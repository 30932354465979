import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on lab security`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This policy establishes the information security requirements to help
manage and safeguard lab resources and Data Migrators networks by
minimizing the exposure of critical infrastructure and information
assets to threats that may result from unprotected hosts and
unauthorized access.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all employees, contractors, consultants,
temporary and other workers at Data Migrators and its subsidiaries
must adhere to this policy. This policy applies to Data Migrators
owned and managed labs, including labs outside the corporate firewall
(DMZ).`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`General Requirements`}</h3>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`Lab owning organizations are responsible for assigning lab
managers, a point of contact (POC), and a back-up POC for
each lab. Lab owners must maintain up-to-date POC
information with InfoSec and the Corporate Enterprise
Management Team. Lab managers or their backup must be
available around-the-clock for emergencies, otherwise
actions will be taken without their involvement.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Lab managers are responsible for the security of their labs
and the lab’s impact on the corporate production network
and any other networks. Lab managers are responsible for
adherence to this policy and associated processes. Where
policies and procedures are undefined lab managers must do
their best to safeguard Data Migrators from security
vulnerabilities.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Lab managers are responsible for the lab’s compliance with
all Data Migrators security policies.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Lab Manager is responsible for controlling lab access.
Access to any given lab will only be granted by the lab
manager or designee, to those individuals with an immediate
business need within the lab, either short-term or as
defined by their ongoing job function. This includes
continually monitoring the access list to ensure that those
who no longer require access to the lab have their access
terminated.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All user passwords must comply with Data Migrators’
`}<a parentName="p" {...{
            "href": "password_construction_guidelines"
          }}>{`Password Construction Guidelines`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Individual user accounts on any lab device must be deleted
when no longer authorized within three (3) days. Group
account passwords on lab computers (Unix, windows, etc) must
be changed quarterly (once every 3 months).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`PC-based lab computers must have Data Migrators’
standard, supported anti-virus software installed and
scheduled to run at regular intervals. In addition, the
anti-virus software and the virus pattern files must be kept
up-to-date. Virus-infected computers must be removed from
the network until they are verified as virus-free. Lab
Admins/Lab Managers are responsible for creating procedures
that ensure anti-virus software is run at regular intervals,
and computers are verified as virus-free.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any activities with the intention to create and/or
distribute malicious programs into Data Migrators’
networks (e.g., viruses, worms, Trojan horses, e-mail bombs,
etc.) are prohibited, in accordance with the `}<a parentName="p" {...{
            "href": "acceptable_use_policy"
          }}>{`Acceptable Use
Policy`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`No lab shall provide production services. Production
services are defined as ongoing and shared business critical
services that generate revenue streams or provide customer
capabilities. These should be managed by a external support organization.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In accordance with `}<em parentName="p">{`the Data Classification Policy`}</em>{`,
information that is marked as Data Migrators Highly
Confidential or Data Migrators Restricted is prohibited on
lab equipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Immediate access to equipment and system logs must be
granted to members of InfoSec and the Network Support
Organization upon request, in accordance with the `}<em parentName="p">{`Audit
Policy`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`InfoSec will address non-compliance waiver requests on a
case-by-case basis and approve waivers if justified.`}</p>
      </li>
    </ol>
    <h3>{`Internal Lab Security Requirements`}</h3>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`The Network Support Organization must maintain a firewall
device between the corporate production network and all lab
equipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Network Support Organization and/or InfoSec reserve the
right to interrupt lab connections that impact the corporate
production network negatively or pose a security risk.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Network Support Organization must record all lab IP
addresses, which are routed within Data Migrators
networks, in Enterprise Address Management database along
with current contact information for that lab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any lab that wants to add an external connection must
provide a diagram and documentation to InfoSec with business
justification, the equipment, and the IP address space
information. InfoSec will review for security concerns and
must approve before such connections are implemented.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All traffic between the corporate production and the lab
network must go through a Network Support Organization
maintained firewall. Lab network devices (including
wireless) must not cross-connect the lab and production
networks.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Original firewall configurations and any changes thereto
must be reviewed and approved by InfoSec. InfoSec may
require security improvements as needed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Labs are prohibited from engaging in port scanning, network
auto-discovery, traffic spamming/flooding, and other similar
activities that negatively impact the corporate network
and/or non-Data Migrators networks. These activities must
be restricted within the lab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Traffic between production networks and lab networks, as
well as traffic between separate lab networks, is permitted
based on business needs and as long as the traffic does not
negatively impact on other networks. Labs must not advertise
network services that may compromise production network
services or put lab confidential information at risk.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`InfoSec reserves the right to audit all lab-related data and
administration processes at any time, including but not
limited to, inbound and outbound packets, firewalls and
network peripherals.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Lab owned gateway devices are required to comply with all
Data Migrators product security advisories and must
authenticate against the Corporate Authentication servers.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The enable password for all lab owned gateway devices must
be different from all other equipment passwords in the lab.
The password must be in accordance with Data Migrators’
`}<a parentName="p" {...{
            "href": "password_protection_policy"
          }}>{`Password Protection Policy`}</a>{` and
`}<a parentName="p" {...{
            "href": "password_construction_guidelines"
          }}>{`Password Construction Guidelines`}</a>{`.
The password will only be provided to
those who are authorized to administer the lab network.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In labs where non-Data Migrators personnel have physical
access (e.g., training labs), direct connectivity to the
corporate production network is not allowed. Additionally,
no Data Migrators confidential information can reside on
any computer equipment in these labs. Connectivity for
authorized personnel from these labs can be allowed to the
corporate production network only if authenticated against
the Corporate Authentication servers, temporary access lists
(lock and key), SSH, client VPNs, or similar technology
approved by InfoSec.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Lab networks with external connections are prohibited from
connecting to the corporate production network or other
internal networks through a direct connection, wireless
connection, or other computing equipment.`}</p>
      </li>
    </ol>
    <h3>{`DMZ Lab Security Requirements`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`New DMZ labs require a business justification and VP-level
approval from the business unit. Changes to the connectivity
or purpose of an existing DMZ lab must be reviewed and
approved by the InfoSec Team.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`DMZ labs must be in a physically separate room, cage, or
secured lockable rack with limited access. In addition, the
Lab Manager must maintain a list of who has access to the
equipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`DMZ lab POCs must maintain network devices deployed in the
DMZ lab up to the network support organization point of
demarcation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`DMZ labs must not connect to corporate internal networks,
either directly, logically (for example, IPSEC tunnel),
through a wireless connection, or multi-homed machine.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`An approved network support organization must maintain a
firewall device between the DMZ lab and the Internet.
Firewall devices must be configured based on least privilege
access principles and the DMZ lab business requirements.
Original firewall configurations and subsequent changes must
be reviewed and approved by the InfoSec Team. All traffic
between the DMZ lab and the Internet must go through the
approved firewall. Cross-connections that bypass the
firewall device are strictly prohibited.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All routers and switches not used for testing and/or
training must conform to the `}<a parentName="p" {...{
            "href": "http://wwwin.cisco.com/infosec/guidelines/iosrouter.shtml"
          }}>{`DMZ
Router`}</a>{`
and `}<a parentName="p" {...{
            "href": "http://ens-sj1.cisco.com/ISNETWORK/isg/ethernet/"
          }}>{`Switch standardization
documents`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Operating systems of all hosts internal to the DMZ lab
running Internet Services must be configured to the secure
host installation and configuration standards published the
InfoSec Team.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Remote administration must be performed over secure channels
(for example, encrypted network connections using SSH or
IPSEC) or console access independent from the DMZ networks.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`DMZ lab devices must not be an open proxy to the Internet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Network Support Organization and InfoSec reserve the
right to interrupt lab connections if a security concern
exists.`}</p>
      </li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Audit Policy`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Acceptable Use Policy`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Data Classification Policy`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Password Policy`}</p>
      </li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`DMZ`}</li>
      <li parentName="ul">{`Firewall`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      